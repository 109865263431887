import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Button, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | MessPirit
			</title>
			<meta name={"description"} content={"Ласкаво просимо до Террагера: святкування футбольної сутності "} />
			<meta property={"og:title"} content={"Головна | MessPirit"} />
			<meta property={"og:description"} content={"Ласкаво просимо до Террагера: святкування футбольної сутності "} />
			<meta property={"og:image"} content={"https://ua.messpirit.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ua.messpirit.com/img/188899.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ua.messpirit.com/img/188899.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ua.messpirit.com/img/188899.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ua.messpirit.com/img/188899.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ua.messpirit.com/img/188899.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ua.messpirit.com/img/188899.png"} />
			<meta name={"msapplication-TileColor"} content={"https://ua.messpirit.com/img/188899.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0" background="--color-light">
			<Box
				margin="0px 0px 32px 0px"
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 33px 0px"
				display="flex"
				flex-direction="column"
				align-items="center"
				lg-padding="15px 15px 15px 15px"
				sm-margin="0px 0px 10px 0px"
				justify-content="center"
			>
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					MessPirit{" "}
					<br />
					{" "}Футбольне спорядження світового класу
				</Text>
				<Text font="--lead" color="--darkL2" text-align="center" md-width="90%">
					Кідуйте на MessPirit, ваш преміальний онлайн-ринок футболу. Від фірмових футбольних черевиків до справжніх трикотажних команд, ми підключаємо ентузіастів до найкращої футбольної передачі у світі.Заняття глибоко в кураційні колекції та переживайте футбол, як ніколи.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-wrap="wrap"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
				>
					<Image
						src="https://ua.messpirit.com/img/1.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="522px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="50% 100%"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
						Автентичне спорядження для справжніх любителів футболу
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--black">
						Відкрийте світ футболу з MessPirit, вашій довіреній ринку для брендів вищого рівня та преміум-футбольного обладнання, одягу тощо.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="center"
					justify-content="center"
					md-border-width="0px"
					flex-direction="column"
					padding="0"
				>
					<Button
						font="--lead"
						margin="20px"
						type="link"
						href="/contact-us"
						text-decoration-line="initial"
						background="--color-green"
						border-radius="8px"
					>
						Зв'яжіться з нами
					</Button>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="80px 0 80px 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Виконані колекції{" "}
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Взуття преміум-продуктивності
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Знайдіть черевики від провідних брендів, пропонуючи ідеальну суміш стилю та функціональності.Незалежно від того, чи є ви в класичній шкіряній чи сучасній синтетиці, у нас є щось для кожного гравця.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Колекція командних трикотажних виробів
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Підтримуйте свою команду в стилі!Заняття в наш широкий спектр трикотажних виробів з клубів та національних команд по всьому світу.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Готові до матчу
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Від повсякденних кікабатів до матчів ліги, вибирайте кураторську колекцію футболів, які обіцяють якість та довговічність.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Вивчити більше
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Навчання необхідності
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Підніміть свої практичні заняття найвищим обладнанням від відомих брендів.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Воротар обов'язково
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Купуйте рукавички, трикотажні вироби та захисна передача для останньої лінії оборони.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Для шанувальників
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Від шарфів до прапорів, занурюйтесь у пристрасть і гордість футбольного фандому.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});